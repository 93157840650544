import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { BrandDnaiLogo } from '@assets/icons';
import SwitchWorkspaceForm from '@pages/select-workspace/components/SwitchWorkspaceForm';
import RoutesPath from '@routes/constants';
import { customer } from '@store/atoms/customer';

import SettingsOption from './settings-option/SettingsOption';

const SideNav = () => {
  const customerData = useRecoilValue(customer);

  return (
    <div className="w-[200px] min-w-[200px] h-screen flex flex-col justify-between py-6 pl-5 border-r border-gray-6">
      <div className="flex flex-col">
        <Link
          to={RoutesPath.BRAND_LIBRARY}
          className="flex justify-center items-center pr-5"
        >
          {customerData?.metadata?.logoUrl ? (
            <img
              src={customerData.metadata.logoUrl}
              alt="Brand-DNAI logo"
              className="h-14 "
            />
          ) : (
            <BrandDnaiLogo className="h-9 w-40 flex justify-center items-center pr-5" />
          )}
        </Link>
        <div className="mt-8 mb-4 font-bold text-xs text-dark-2">WORKSPACE</div>
        <SwitchWorkspaceForm />
      </div>
      <div className="flex flex-col gap-4">
        <SettingsOption />
        {/* To be implemented in phase 2
        <div className="flex flex-row gap-2">
          <QuestionMark />
          <span className="text-[13px] font-semibold">Get help</span>
        </div>
        <div className="flex flex-row gap-2">
          <Message />
          <span className="text-[13px] font-semibold">What's new</span>
        </div> */}
      </div>
    </div>
  );
};

export default SideNav;
