import { FC } from 'react';

export interface SpinnerProps {
  color?: string;
  isLarge?: boolean;
  classOverride?: string;
}

const Spinner: FC<SpinnerProps> = ({
  color = 'bg-black',
  isLarge = false,
  classOverride = '',
}) => {
  return (
    <div
      className={`flex-grow w-full flex flex-row justify-center items-center ${classOverride}`}
    >
      <div className={isLarge ? 'spinner-large' : 'spinner'}>
        <div className={`bounce1 ${color}`} />
        <div className={`bounce2 ${color}`} />
        <div className={`bounce3 ${color}`} />
      </div>
    </div>
  );
};

export default Spinner;
