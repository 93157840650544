export enum UserPermissions {
  VIEW_WORKSPACES = 'view-workspaces',
  CREATE_WORKSPACE = 'create-workspace',
  EDIT_WORKSPACE = 'edit-workspace',

  VIEW_BRAND_LIBRARY = 'view-brand-library',
  MANAGE_BRAND_LIBRARY = 'manage-brand-library',

  INTERACT_WITH_CHAT = 'interact-with-chat',

  VIEW_INTEGRATIONS = 'view-integrations',
  EDIT_INTEGRATIONS = 'edit-integrations',

  VIEW_TEAMS = 'view-teams',
  EDIT_TEAM = 'edit-team',
}
