import { FC } from 'react';

import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';

import { Locked } from '@assets/icons';
import Tooltip from '@components/tooltip/Tooltip';

interface LockedButtonProps {
  LeftIconComponent?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  buttonText?: string;
  buttonClassname?: string;
  iconClassName?: string;
  lockClassName?: string;
}

const LockedButton: FC<LockedButtonProps> = ({
  LeftIconComponent,
  buttonText,
  buttonClassname,
  iconClassName,
  lockClassName,
}) => {
  return (
    <Tooltip
      isLockTooltip
      RefComponent={
        <button
          className={twMerge(
            classNames(
              'flex items-center h-10 rounded text-gray-3 bg-gray-2 cursor-default justify-between',
              buttonClassname,
            ),
          )}
        >
          <div className="flex items-center justify-start space-x-2">
            {LeftIconComponent && (
              <LeftIconComponent className={iconClassName} />
            )}
            <span>{buttonText}</span>
          </div>
          <Locked
            className={twMerge(classNames('mx-2 h-4 w-4', lockClassName))}
          />
        </button>
      }
    />
  );
};

export default LockedButton;
