import { FC } from 'react';

import { isNil } from 'lodash';
import { useForm } from 'react-hook-form';

import Button from './button/Button';
import Input from './input/Input';
import Modal from './modal/Modal';

interface TextEditModalProps {
  isOpen: boolean;
  onCloseModal: () => {};
  value: string;
  onButtonClick: (data: string) => void;
  title: string;
  isLoading: boolean;
  buttonLabel: string;
}

export const TextEditModal: FC<TextEditModalProps> = ({
  isOpen,
  onCloseModal,
  value,
  onButtonClick,
  title,
  isLoading,
  buttonLabel,
}) => {
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: value ?? '',
    },
  });

  const watchedName = watch('name');

  const onFormSubmit = (data: { name: string }) => {
    const updatedName = data.name.trim();
    onButtonClick(updatedName);
  };

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal}>
      <div className="flex flex-col items-center justify-center p-10 w-[480px]">
        <h1 className="text-lg font-bold mb-2">{title}</h1>
        <form
          className="relative w-full mt-5 space-y-5"
          onSubmit={handleSubmit(onFormSubmit)}
        >
          <div className="flex flex-col space-y-3">
            <Input
              error={isNil(errors.name?.message) ? '' : errors.name.message}
              //TODO generalize validation if needed
              {...register('name', {
                required: true,
                validate: value => {
                  return !!value.trim();
                },
              })}
            />
          </div>
          <Button
            variant="contained"
            type="submit"
            //TODO generalize validation if needed
            isDisabled={!isValid || isLoading || watchedName.trim() === value}
            isLoading={isLoading}
            label={buttonLabel}
          />
        </form>
      </div>
    </Modal>
  );
};
