import { ElementType, FC, useState } from 'react';

import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { usePopper } from 'react-popper';

import { Document, EditPencil, Ellipsis, Locked, Trash } from '@assets/icons';
import { UserPermissions } from '@enums/user-permissions';

import { LockedButton, PermissionAccess, ToggleSwitch } from '..';

interface MoreOptionsProps {
  onClickDelete?: () => void;
  onClickEdit?: () => void;
  onToggle?: () => void;
  enabled?: boolean;
  showLock?: boolean;
  onClickPreview?: () => void;
  isLoading?: boolean;
  classOverride?: string;
}

interface PopoverOptionProps {
  Icon: ElementType;
  label: string;
  onOptionClick: () => void;
  isTogglePresent?: boolean;
  enabled?: boolean;
  isLoading?: boolean;
}

const PopoverOption: FC<PopoverOptionProps> = ({
  Icon,
  label,
  onOptionClick,
  isTogglePresent = false,
  enabled,
  isLoading = false,
}) => {
  return (
    <div
      className={`flex items-center space-x-2 w-full p-2 group ${isLoading ? 'cursor-default' : 'hover:bg-lightest'}`}
      onClick={!isTogglePresent ? onOptionClick : () => {}}
    >
      <Icon className="text-dark-1 h-4 w-4" />
      <div className="text-dark-1 text-sm">{label}</div>
      <div className="flex justify-end pl-10">
        {isTogglePresent && (
          <ToggleSwitch
            isChecked={enabled as boolean}
            isDisabled={isLoading}
            onChange={!isLoading ? onOptionClick : () => {}}
          />
        )}
      </div>
    </div>
  );
};

const MoreOptions: FC<MoreOptionsProps> = ({
  onClickDelete,
  onClickEdit,
  onToggle = () => {},
  showLock = false,
  enabled = false,
  onClickPreview,
  isLoading = false,
  classOverride = '',
}) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  return (
    <Popover>
      {() => (
        <>
          <PopoverButton
            ref={
              setReferenceElement as (
                instance: HTMLButtonElement | null,
              ) => void
            }
            as="div"
            className={`flex cursor-pointer justify-center items-center w-10 h-10 rounded focus:outline-none ${classOverride}`}
          >
            <Ellipsis />
          </PopoverButton>
          <PopoverPanel
            className="!w-40 py-2 bg-white rounded-md shadow-2xl z-10"
            ref={element => setPopperElement(element as HTMLDivElement | null)}
            style={styles.popper}
            {...attributes.popper}
          >
            {onClickPreview && (
              <PopoverOption
                Icon={Document}
                label="Preview"
                onOptionClick={onClickPreview}
              />
            )}
            <PermissionAccess
              permission={UserPermissions.MANAGE_BRAND_LIBRARY}
              AllowedContent={
                <>
                  {showLock && (
                    <PopoverOption
                      Icon={Locked}
                      label="Lock"
                      enabled={enabled}
                      isTogglePresent={true}
                      onOptionClick={onToggle}
                      isLoading={isLoading}
                    />
                  )}
                  {onClickEdit && (
                    <PopoverOption
                      Icon={EditPencil}
                      label="Edit"
                      onOptionClick={onClickEdit}
                    />
                  )}
                  {onClickDelete && (
                    <PopoverOption
                      Icon={Trash}
                      label="Delete"
                      onOptionClick={onClickDelete}
                    />
                  )}
                </>
              }
              DeniedContent={
                <>
                  {showLock && (
                    <LockedButton
                      buttonText="Lock"
                      buttonClassname="pl-3 w-full mb-2"
                    />
                  )}
                  {onClickEdit && (
                    <LockedButton
                      buttonText="Edit"
                      buttonClassname="pl-3 w-full mb-2"
                    />
                  )}
                  {onClickDelete && (
                    <LockedButton
                      buttonText="Delete"
                      buttonClassname="pl-3 w-full"
                    />
                  )}
                </>
              }
            />
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
};

export default MoreOptions;
