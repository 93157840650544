import { FC, ReactNode, useState } from 'react';

import { usePopper } from 'react-popper';

import { PadLock } from '@assets/icons';
import { PopperPlacement } from '@components/popper/Popper';

export interface TooltipProps {
  PopComponent?: ReactNode;
  RefComponent: ReactNode;
  placement?: PopperPlacement;
  isLockTooltip?: boolean;
}

const LockTooltipContent = () => {
  return (
    <div className="bg-white text-sm py-2 px-3 rounded-md whitespace-nowrap w-fit text-center flex items-center space-x-2 shadow-md max-w-max">
      <PadLock className="h-4 w-4" />
      <span className="inline-block">
        You don&apos;t have permission to perform this action. Kindly contact
        the administrator.
      </span>
    </div>
  );
};

const Tooltip: FC<TooltipProps> = ({
  PopComponent,
  RefComponent,
  placement = 'bottom',
  isLockTooltip = false,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 15],
        },
      },
    ],
  });

  return (
    <div
      ref={setReferenceElement}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {RefComponent}
      {isVisible && (
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="z-50"
        >
          {isLockTooltip ? <LockTooltipContent /> : PopComponent}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
