import { FC, useEffect, useState } from 'react';

import { isEmpty, isNil } from 'lodash';
import { useForm } from 'react-hook-form';

import { Button, Input, Modal } from '@components/index';

import SelectWorkspaceDropdown from './SelectWorkspaceDropdown';
import { CreateWorkspaceData, Workspace } from '../types';
import { SelectWorkspaceRefComponent } from './SelectWorkspaceForm';
import { NO_PARENT_CLIENT_ID, NO_PARENT_WORKSPACE } from '../constants';
import { ImportFromParentWorkspace } from '../enums';
import { useListWorkspaces } from '../hooks/use-list-workspaces';

interface AddWorkspaceModalProps {
  isOpen: boolean;
  onClose: () => void;
  onWorkspaceCreation: (data: CreateWorkspaceData) => Promise<void>;
  isCreatingWorkspace: boolean;
}

const AddWorkspaceModal: FC<AddWorkspaceModalProps> = ({
  isOpen,
  onClose,
  onWorkspaceCreation,
  isCreatingWorkspace,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
    },
  });

  const [workspaceOptions, setWorkspaceOptions] = useState<Workspace[]>([]);
  const [selectedOption, setSelectedOption] = useState<Workspace | null>(
    NO_PARENT_WORKSPACE,
  );

  const [activeParentWorkspaceId, setActiveParentWorkspaceId] = useState<
    number | null
  >(null);

  const [searchKeyword, setSearchKeyword] = useState<string | null>(null);

  const {
    workspaceList,
    isLoading: isLoadingWorkspaces,
    hasNextPage,
    fetchNextPage,
  } = useListWorkspaces({
    isActive: true,
    searchKeyword: searchKeyword,
    parentClientId: activeParentWorkspaceId,
    isEnabled: true,
  });

  useEffect(
    function initWorkspaceOptionsList() {
      if (isEmpty(searchKeyword) && isNil(activeParentWorkspaceId)) {
        return setWorkspaceOptions([
          { ...NO_PARENT_WORKSPACE },
          ...workspaceList,
        ]);
      }

      setWorkspaceOptions(workspaceList);
    },
    [searchKeyword, activeParentWorkspaceId, workspaceList],
  );

  const onFormSubmit = (formData: { name: string }) => {
    const createWorkspaceData = {
      name: formData.name,
      ...(selectedOption?.id !== NO_PARENT_CLIENT_ID && {
        parentClientId: selectedOption?.id,
        toBeImported: [
          ImportFromParentWorkspace.BRAND_LIBRARY,
          ImportFromParentWorkspace.USERS,
        ],
      }),
    };

    onWorkspaceCreation(createWorkspaceData);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col items-center justify-center py-10 w-[400px]">
        <div className="flex flex-col items-center">
          <h1 className="text-lg text-center font-bold my-3">
            You’re adding a new workspace
          </h1>
          <form
            className="w-[400px] flex flex-col gap-1.5 my-2 px-12"
            onSubmit={handleSubmit(onFormSubmit)}
          >
            <Input
              containerClass="mb-2"
              error={errors?.['name']?.type}
              placeholder="Name your workspace"
              {...register('name', {
                required: {
                  value: true,
                  message: 'Workspace name is required',
                },
              })}
              label="Workspace name"
            />
            <label htmlFor="workspaceId" className="text-sm font-medium">
              List of workspace
            </label>
            <SelectWorkspaceDropdown
              options={workspaceOptions}
              isLoadingOptions={isLoadingWorkspaces}
              hasMoreOptions={hasNextPage}
              fetchMoreOptions={fetchNextPage}
              selectedOption={selectedOption}
              onOptionSelect={option => setSelectedOption(option)}
              setSearchKeyword={setSearchKeyword}
              activeParentId={activeParentWorkspaceId}
              setActiveParentId={setActiveParentWorkspaceId}
              popperPlacement="bottom-start"
              PopperRefComponent={SelectWorkspaceRefComponent}
              classOverride="min-w-[200px] max-h-[300px]"
            />
            <Button
              variant="contained"
              classOverride="mt-2"
              label="Accept and add workspace"
              isDisabled={!isValid || isSubmitting || isCreatingWorkspace}
              isLoading={isSubmitting || isCreatingWorkspace}
              type="submit"
            />
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default AddWorkspaceModal;
