export enum Language {
  ENGLISH = 'en',
  ARABIC = 'ar',
  BENGALI = 'bn',
  BOSNIAN = 'bs',
  CATALAN = 'ca',
  CHINESE = 'cn',
  TRADITIONAL_CHINESE = 'zh',
  CROATIAN = 'hr',
  CZECH = 'cs',
  DANISH = 'da',
  DUTCH = 'nl',
  FINNISH = 'fi',
  FRENCH = 'fr',
  GERMAN = 'de',
  GREEK = 'el',
  GUJARATI = 'gu',
  HEBREW = 'he',
  HINDI = 'hi',
  HUNGARIAN = 'hu',
  INDONESIA = 'id',
  ITALIAN = 'it',
  JAPANESE = 'jp',
  KANNADA = 'kn',
  KOREAN = 'kr',
  MALAY = 'ms',
  MALAYALAM = 'ml',
  MARATHI = 'mr',
  NORWEGIAN = 'no',
  POLISH = 'pl',
  PORTUGUESE = 'pt',
  PUNJABI = 'pa',
  ROMANIAN = 'ro',
  RUSSIAN = 'ru',
  SERBIAN = 'sr',
  SLOVAKIAN = 'sk',
  SLOVENIAN = 'sl',
  SPANISH = 'es',
  SWEDISH = 'sv',
  TAGALOG = 'tl',
  TAMIL = 'ta',
  TELUGU = 'te',
  THAI = 'th',
  TURKISH = 'tr',
  UKRAINIAN = 'uk',
  VIETNAMESE = 'vn',
}

export enum FileExt {
  BRAND_FILES = 'pdf, .docx, .pptx, .doc, .ppt, .png, .jpg, .ttf, .otf, .hex, .rgb, .jpeg, .webp, .mp4, .mpg, .mpeg, .avi, .webm, .wmv, .tif, .tiff',
  RAW_FILE = '.pdf, .docx, .pptx, .doc, .ppt',
  BRAND_IMAGE = '.png, .jpg',
  FONT = '.ttf, .otf',
  LOGO = '.png',
}

export enum AssetType {
  RAW_FILE = 'RAW_FILE',
  LOGO = 'LOGO',
  FONT = 'FONT',
  COLORS = 'COLORS',
  PRODUCT = 'PRODUCT',
  VIDEO = 'VIDEO',
  KNOWLEDGE = 'KNOWLEDGE',
}

export enum RawFileCategory {
  BRAND_BOOK = 'BRAND_BOOK',
}

export enum ChatRole {
  HUMAN = 'human',
  AI = 'ai',
}

export enum Actions {
  ADD = 'Add',
  EDIT = 'Edit',
}

export enum RawFileTypes {
  PDF = 'pdf',
  DOC = 'doc',
  DOCX = 'docx',
  PPT = 'ppt',
  PPTX = 'pptx',
}

export enum ColorCodeCategory {
  RGB = 'RGB',
  HEX = 'HEX',
}

export enum KnowledgeExtractionStatus {
  PENDING = 1,
  PROGRESS = 2,
  FAILURE = 3,
  SUCCESS = 4,
  BINNED = 5,
  TIMEOUT = 6,
  PARTIALSUCCESS = 7,
  VALIDATING = 8,
  INVALID = 9,
}

export enum PreviewType {
  TEXT = 'TEXT',
  DOCUMENT = 'DOCUMENT',
}
