import { FC } from 'react';

import { Switch } from '@headlessui/react';

interface ToggleSwitchProps {
  isDisabled?: boolean;
  isChecked: boolean;
  onChange: () => void;
  label?: string;
  classOverride?: string;
}

const ToggleSwitch: FC<ToggleSwitchProps> = ({
  isChecked,
  onChange,
  label,
  isDisabled,
}) => {
  return (
    <div className="flex gap-2 items-center">
      <div className="flex items-center">
        {label && <span className="dark:text-gray-6">{label}</span>}
        <Switch
          checked={isChecked}
          disabled={isDisabled}
          onChange={onChange}
          className={`${
            isChecked ? 'bg-primary' : 'bg-gray-200'
          } relative inline-flex h-6 w-11 items-center rounded-full transition-colors`}
        >
          <span
            className={`${
              isChecked ? 'translate-x-6' : 'translate-x-1'
            } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
          />
        </Switch>
      </div>
    </div>
  );
};

export default ToggleSwitch;
