import { atom } from 'recoil';

import { UserPermissions } from '@enums/user-permissions';

import { Atoms } from '../constants';

export const userPermissions = atom<UserPermissions[]>({
  key: Atoms.userPermissions,
  default: [],
});
