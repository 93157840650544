export const LocalStorageKeys = {
  IS_LOGGED_IN: 'IS_LOGGED_IN',
};

export const SessionStorageKeys = {
  SESSION_EXPIRED_MESSAGE: 'SESSION_EXPIRED_MESSAGE',
};

export const isLoggedInUser = () => {
  return localStorage.getItem(LocalStorageKeys.IS_LOGGED_IN) === 'true';
};
