import { AxiosError } from 'axios';
import { isNil } from 'lodash';

import { ErrorCodes, ErrorMessages } from '@enums/error';
import RoutesPath from '@routes/constants';

import { LocalStorageKeys, SessionStorageKeys } from './auth';

interface ErrorResponseData {
  header?: string;
  message?: string;
  msg?: string;
  code?: string;
}

export const getErrorMessage = (error: AxiosError) => {
  const { response } = error || {};
  const { status, data } = response || {};
  const { message, code, header } = (data || {}) as ErrorResponseData;

  if (status === 401 && code === ErrorCodes.BD_UNAUTHORIZED) {
    if (message || header)
      sessionStorage.setItem(
        SessionStorageKeys.SESSION_EXPIRED_MESSAGE,
        JSON.stringify({
          message: message,
          header: header || '',
        }),
      );
    window.location.href = window.location.origin + RoutesPath.LOGIN;
    localStorage.removeItem(LocalStorageKeys.IS_LOGGED_IN);

    return;
  }

  if (!isNil(message)) {
    return message;
  }

  return ErrorMessages.DEFAULT;
};
